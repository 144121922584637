import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from './user.service';
import { BusinessDetailService } from './business-detail.service';
import { isPlatformServer } from '@angular/common';

export const authGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);
  const businessService = inject(BusinessDetailService);
  const platformId = inject(PLATFORM_ID);

  if(isPlatformServer(platformId)){
    if(state.url === "/catalog/cart"){
      return false;
    } 
    return true;
  }

  return userService.isAuthenticated().then(data=>{
    if(!data){
      router.navigate([{outlets: {popup: 'login'}}], {queryParams: {
        "referrer": "/catalog/catalog-list"
      }})
    }

    if(data?.status === "PENDING"){
      router.navigate(["/catalog/new-seller"])
    }
    return data?true:false;
  });
};

export const restrictedAuthGuard: CanActivateFn = () => {
  const userService = inject(UserService);
  const router = inject(Router);
  return userService.isAuthenticated().then(data=>{
    if(data){
      router.navigate(["/catalog"])
    }
    return !data;
  });
};
