export interface Cartproducuts{
    catalogId:string,
    id:string,
    imageId:string,
    inStock:boolean,
    orderBasedOnType:string
    price:string,
    quantity:number,
    priceType:string,
    title:string,
    unitOfMeasure:string,
    unitOfMeasureValue:string,
    varientsInString:string,
    sku: string
}
 
export function cartProductDto(data:any):Cartproducuts{
    return{
        catalogId:data['catalogId'],
        id: data['id'],
        imageId: data['imageId'],
        inStock: data['inStock'],
        orderBasedOnType: data['orderBasedOnType'],
        price: data['price'],
        quantity: data['quantity'],
        priceType: data['priceType'],
        title: data['title'],
        unitOfMeasure: data['unitOfMeasure'],
        unitOfMeasureValue: data['unitOfMeasureValue'],
        varientsInString: data['varientsInString']?.replaceAll("=", ": ")?.replaceAll(";", " | "),
        sku: data["sku"]?.trim()
    }
}

export interface Cartdata {
    cartProducts:Cartproducuts[],
    price:string
    size:number
}

export function cartDataDto(data:any):Cartdata {
    const cartList = data['cartProducts']?.map((d:any)=>cartProductDto(d))
    return{
        cartProducts: cartList,
        price:data['price'],
        size:data['size']
    }
}